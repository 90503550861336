import React, { createContext, useReducer, useCallback } from "react"
import { contentReducer } from "../reducer/content-reducer"

// A starting Foo object for the reducer/context
const startingModal = {
  content: null,
  name: null,
  params: null,
  open: false,
}

// A starting Bar object for the reducer/context
const startingFixedButton = {
  content: null,
  name: null,
  params: null,
}

export const ContentContext = createContext([
  // Starting values for the context. Really they should never be seen
  // provided the hooks are only used inside children of ContentProvider.
  { modal: startingModal, fixedButton: startingFixedButton },
  () => {},
])

// This is the parent component that will provide all its component
// children with access to the state and dispatch functions. All
// children within the same ContentProvider will have the same state
// available to them.
export function ContentProvider({ children }) {
  const [state, _dispatch] = useReducer(contentReducer, {
    modal: startingModal,
    fixedButton: startingFixedButton,
  })

  // This is my funky idea for a dispatch function that takes two
  // arguments instead of a single object with a type and payload.
  // The rest argument business is so that it can be correctly
  // typed for actions without payloads so they can be called with
  // just the action type, see the above `Dispatcher` type. In this
  // project an example would be `dispatch('doubleFoo')`.
  const dispatch = useCallback((type, ...payload) => {
    _dispatch({ type, payload: payload[0] })
  }, [])
  return (
    <ContentContext.Provider value={[state, dispatch]}>
      {children}
    </ContentContext.Provider>
  )
}
