import React from "react"
import { toast } from "react-toastify"
const STORAGE_KEY = "GATSBY_ECOMMERCE_JAPELOUP_"

const initialState = {
  cart: [],
  numberOfItemsInCart: 0,
  total: 0,
}

const SiteContext = React.createContext()

function calculateTotal(cart) {
  // TODO actuellement Tarif FR -> Modifier en tarif Belgique ou France
  const total = cart.reduce((acc, next) => {
    const quantity = next.quantity
    acc = acc + JSON.parse(next.price_fr) * quantity
    return acc
  }, 0)
  return total
}

class ContextProviderComponent extends React.Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      const storageState = window.localStorage.getItem(STORAGE_KEY)
      if (!storageState) {
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(initialState))
      }
    }
  }

  setItemQuantity = (item) => {
    if (typeof window !== "undefined") {
      const storageState = JSON.parse(window.localStorage.getItem(STORAGE_KEY))
      const { cart } = storageState
      const index = cart.findIndex((cartItem) => cartItem.id === item.id)
      cart[index].quantity = item.quantity
      window.localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          cart,
          numberOfItemsInCart: cart.length,
          total: calculateTotal(cart),
        })
      )
      this.forceUpdate()
    }
  }

  addToCart = (item) => {
    if (typeof window !== "undefined") {
      let newItemQuantity = item.quantity
      const storageState = JSON.parse(window.localStorage.getItem(STORAGE_KEY))
      const { cart } = storageState
      if (cart.length) {
        const index = cart.findIndex((cartItem) => cartItem.id === item.id)
        if (index >= Number(0)) {
          /* If this item is already in the cart, update the quantity */
          cart[index].quantity = cart[index].quantity + item.quantity
          newItemQuantity = cart[index].quantity
        } else {
          /* If this item is not yet in the cart, add it */
          cart.push(item)
        }
      } else {
        /* If no items in the cart, add the first item. */
        cart.push(item)
      }

      window.localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          cart,
          numberOfItemsInCart: cart.length,
          total: calculateTotal(cart),
        })
      )
      toast.success(
        <p className="flex mb-0 text-stone-500 text-lg items-center justify-center italic font-serif font-semibold">
          {/* <IoAdd size={18} /> */}
          <span className="font-sans text-base font-semibold italic pr-1.5">
            x{newItemQuantity}
          </span>
          <span>{item.name} dans le panier !</span>
        </p>,
        {
          position: toast.POSITION.TOP_LEFT,
        }
      )
      this.forceUpdate()
    }
  }

  getCartItem = (item) => {
    if (typeof window !== "undefined") {
      let totalItemQuantity = 0
      const storageState = JSON.parse(window.localStorage.getItem(STORAGE_KEY))
      const { cart } = storageState
      if (cart.length) {
        const index = cart.findIndex((cartItem) => cartItem.id === item.id)
        if (index >= Number(0)) {
          /* If this item is already in the cart, update the quantity */
          totalItemQuantity = cart[index].quantity
        }
      }
      return totalItemQuantity
    }
  }

  removeFromCart = (item) => {
    if (typeof window !== "undefined") {
      const storageState = JSON.parse(window.localStorage.getItem(STORAGE_KEY))
      let { cart } = storageState
      cart = cart.filter((c) => c.id !== item.id)

      window.localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          cart,
          numberOfItemsInCart: cart.length,
          total: calculateTotal(cart),
        })
      )
      this.forceUpdate()
    }
  }

  clearCart = () => {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(initialState))
    this.forceUpdate()
  }

  render() {
    let state = initialState
    if (typeof window !== "undefined") {
      const storageState = window.localStorage.getItem(STORAGE_KEY)
      if (storageState) {
        state = JSON.parse(storageState)
      }
    }

    return (
      <SiteContext.Provider
        value={{
          ...state,
          addToCart: this.addToCart,
          getCartItem: this.getCartItem,
          clearCart: this.clearCart,
          removeFromCart: this.removeFromCart,
          setItemQuantity: this.setItemQuantity,
        }}
      >
        {this.props.children}
      </SiteContext.Provider>
    )
  }
}

export { SiteContext, ContextProviderComponent }
