import React, { useState, useEffect, useRef, useContext } from "react"
import { Link } from "gatsby"
import { IoMenuOutline, IoPerson } from "react-icons/io5"
import { IoClose } from "react-icons/io5"
// import Flag from "./Flag"
import SocialNetworks from "./SocialNetworks"
import Logo from "./Logo"
import CartLink from "./CartLink"
// import { titleIfy, slugify } from "../../utils/helpers"
import BaseContext from "../context/baseContext"
import { useModal } from "../services/context/use-modal"
import { useFixedButton } from "../services/context/use-fixed-button"
import Modal from "./modals/modal"
import { ButtonField } from "./Fields"

export default function Menu({ links, fixed }) {
  const [menuOpen, setMenuOpen] = useState(false)
  // const [loading, setLoading] = useState(true)
  const [compactBar, setCompactBar] = useState(false)
  const menuRef = useRef(null)
  const headerRef = useContext(BaseContext)
  const { modal } = useModal()
  const { fixedButton } = useFixedButton()

  const handleActionBar = (window) => {
    if (
      headerRef.current === null ||
      (headerRef.current !== null &&
        menuRef.current !== null &&
        menuRef.current.offsetHeight + window.pageYOffset >
          headerRef.current.offsetHeight)
    ) {
      setCompactBar(true)
    } else {
      setCompactBar(false)
    }
  }

  useEffect(() => {
    if (window !== `undefined` && document !== `undefined`) {
      handleActionBar(window)
      window.addEventListener("scroll", () => {
        handleActionBar(window)
      })

      document.addEventListener("keydown", (e) => {
        if (e.keyCode === 27 && menuOpen) setMenuOpen(false)
      })
      // setTimeout(() => {
      //   setLoading(false)
      // }, 500)
    }
  })
  return (
    <>
      {fixedButton.content && fixedButton.params && (
        <div className="fixed bottom-10 right-10 z-[100]">
          <ButtonField {...fixedButton.params}>
            {fixedButton.content}
          </ButtonField>
        </div>
      )}
      <Modal {...modal.params} isOpen={modal.open}>
        {modal.content}
      </Modal>
      <div
        ref={menuRef}
        className={`flex transform linear transition-all duration-300 ease-in-out w-full top-0 items-center justify-between px-2 sm:px-0 h-20 text-stone-800 z-30 ${
          compactBar
            ? "bg-white shadow-lg shadow-stone-50-500/50"
            : "bg-gradient-to-b from-blackOpacity50 sm:from-transparent sm:bg-transparent"
        } ${fixed && "fixed"} ${
          menuOpen ? "-translate-y-full" : "translate-y-0"
        }`}
      >
        <div className="flex items-start">
          <Logo
            mod="menu"
            className="h-20 w-full py-1 px-4 sm:px-6"
            background="transparent"
            color={compactBar ? "#1c1917" : "#efefef"}
            color2={compactBar ? "#a8a29e" : "#cfcfcf"}
          />
        </div>

        <div className="flex self-center"></div>
        <div className="flex items-center">
          <div className="flex justify-between bg-transparent">
            <div className="flex flex-1 justify-end pr-2 sm:pr-4 relative">
              {/* <Flag whiteBg={compactBar} /> */}
              {JSON.parse(process.env.GATSBY_ECOMMERCE) === true ? (
                <React.Fragment>
                  <Link
                    title="Mon compte"
                    to="/user/account"
                    className="h-full flex justify-center items-center px-2 sm:px-3 pb-[5px]"
                  >
                    <IoPerson
                      className={compactBar ? "text-gray-700" : "text-gray-100"}
                      size={28}
                    />
                  </Link>
                  <CartLink numberOfItemsInCart={10} compactBar={compactBar} />
                </React.Fragment>
              ) : (
                ""
              )}
              {/* TODO DROPDOWN */}
              <button
                className="ml-2 mb-0.5"
                aria-label="Ouvrir le menu"
                title="Ouvrir le menu"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <IoMenuOutline
                  className={compactBar ? "text-gray-700" : "text-gray-100"}
                  size={50}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <aside
        className={`transform bg-white flex flex-wrap content-between top-0 right-0 w-64 fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${
          menuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col w-full mb-4 relative">
          <span className="z-20 absolute top-2 right-2">
            <button
              aria-label="Fermer le menu"
              type="button"
              onClick={() => {
                setMenuOpen(false)
              }}
              className="text-gray-500 focus:outline-none focus:border-none hover:bg-gray-400 hover:bg-opacity-25 p-2 rounded-full inline-flex items-center"
            >
              <IoClose size="15" color="inherit" />
            </button>
          </span>
          <Link to="/" title="Page d'accueil">
            <span
              role="button"
              aria-label="Le Vignoble de Jape-loup"
              tabIndex="-1"
              onKeyDown={() => {
                setMenuOpen(false)
              }}
              onClick={() => {
                setMenuOpen(false)
              }}
              className="flex w-full items-center p-0 pt-4"
            >
              <Logo
                mod="simple"
                className="w-full h-full pb-4"
                background="transparent"
                color="#1c1917"
                height="100%"
                color2="#292524"
              />
            </span>
          </Link>
          <ul className="mx-0 mb-0 mt-4 sm:mt-10">
            {links.map((l, i) => (
              <li key={i} className="m-0">
                <Link to={l.link}>
                  <span
                    role="button"
                    tabIndex="-1"
                    onKeyDown={() => {
                      setMenuOpen(false)
                    }}
                    onClick={() => {
                      setMenuOpen(false)
                    }}
                    key={i}
                    className="flex w-full items-center content-center px-4 py-4"
                  >
                    <span className="text-4xl w-full text-center font-base text-gray-600">
                      {l.name}
                    </span>
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex items-center p-4 w-full text-center text-stone-100 bg-stone-900">
          <div className="mx-auto grid">
            <SocialNetworks
              icon={true}
              column={true}
              MinimalInstagram="https://www.instagram.com/vignoble_de_jape_loup/"
              MinimalFacebook="https://www.facebook.com/Vignoble-de-Jape-loup-103718091432387/"
            />
          </div>
        </div>
      </aside>
      <div
        role="button"
        aria-label="Fermer le menu"
        tabIndex={-42}
        onKeyUp={() => setMenuOpen(false)}
        onClick={() => setMenuOpen(false)}
        className={`menu-overlay z-20 absolute w-full h-full bg-gray-900 opacity-80 ${
          menuOpen ? "block" : "hidden"
        }`}
      ></div>
    </>
  )
}
