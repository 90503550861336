import React from "react"
import classNames from "classnames"
import { VscLoading } from "react-icons/vsc"

export const TextField = ({
  fullWidth,
  className,
  id,
  placeholder,
  name,
  label,
  value,
  required,
  type,
  onChange,
  error,
  helperText,
}) => {
  return (
    <div className={classNames("relative", className)}>
      <input
        placeholder={placeholder}
        className={classNames(
          "peer px-3 font-semibold shadow font-body tracking-wide bg-white dark:bg-gray-950 h-14 text-lg text-gray-900 dark:text-gray-200 border-b-2 border-gray-300 focus:border-gray-700 dark:focus:border-gray-200 placeholder-transparent focus:outline-none rounded-xl",
          {
            "w-full": fullWidth,
            "": error,
          }
        )}
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
      />
      {label && (
        <label
          className={classNames(
            "absolute font-bold font-body left-0 -top-6 text-gray-600 dark:text-gray-400 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-3 peer-focus:-top-6 peer-focus:text-gray-600 dark:peer-focus:text-gray-200 peer-focus:text-sm",
            {
              "": error,
            }
          )}
          htmlFor={id}
        >
          {label + " "}
          {required && (
            <span className={`${error ? "text-red-500" : "text-blue-500"}`}>
              *
            </span>
          )}
        </label>
      )}
      <small
        className={classNames(
          "text-gray-800 px-4 py-3 bg-green-300 font-bold tracking-wider text-sm",
          {
            block: helperText,
            hidden: !helperText,
          }
        )}
      >
        {helperText}
      </small>
    </div>
  )
}

export const TextareaField = ({
  fullWidth,
  className,
  id,
  rows,
  placeholder,
  name,
  label,
  value,
  required,
  type,
  onChange,
  error,
  helperText,
}) => {
  return (
    <div className={classNames("relative", className)}>
      <textarea
        rows={rows ? rows : 3}
        placeholder={placeholder}
        className={classNames(
          "peer shadow resize-y px-3 font-semibold font-body tracking-wide bg-white dark:bg-gray-950 text-lg text-gray-900 dark:text-gray-200 border-b-2 border-gray-300 focus:border-gray-700 dark:focus:border-gray-200 placeholder-transparent focus:outline-none rounded-xl",
          {
            "w-full": fullWidth,
            "": error,
          }
        )}
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
      ></textarea>
      {label && (
        <label
          className={classNames(
            "absolute font-bold font-body left-0 -top-6 text-gray-600 dark:text-gray-400 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-3 peer-focus:-top-6 peer-focus:text-gray-600 dark:peer-focus:text-gray-200 peer-focus:text-sm",
            {
              "": error,
            }
          )}
          htmlFor={id}
        >
          {label + " "}
          {required && (
            <span className={`${error ? "text-red-500" : "text-blue-500"}`}>
              *
            </span>
          )}
        </label>
      )}
      <small
        className={classNames(
          "text-gray-800 px-4 py-3 bg-green-300 font-bold tracking-wider text-sm",
          {
            block: helperText,
            hidden: !helperText,
          }
        )}
      >
        {helperText}
      </small>
    </div>
  )
}

export const SelectField = ({
  fullWidth,
  className,
  id,
  placeholder,
  name,
  label,
  options,
  value,
  required,
  type,
  onChange,
  error,
  helperText,
}) => {
  return (
    <div className={classNames("relative", className)}>
      <select
        placeholder={placeholder}
        className={classNames(
          "peer min-w-max shadow px-3 bg-white dark:bg-gray-950 font-semibold tracking-wide bg-transparent h-14 text-lg text-gray-800 dark:text-gray-200 border-b-2 border-gray-300 focus:border-gray-700 placeholder-transparent focus:outline-none rounded-xl",
          {
            "w-full": fullWidth,
            "": error,
          }
        )}
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <option
            aria-label={option.label}
            disabled={option.disabled}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
      {label && (
        <label
          className={classNames(
            "absolute font-bold left-0 -top-6 text-gray-600 dark:text-gray-400 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-3 peer-focus:-top-6 peer-focus:text-gray-600 dark:peer-focus:text-gray-400 peer-focus:text-sm",
            {
              "": error,
            }
          )}
          htmlFor={id}
        >
          {label + " "}
          {required && (
            <span className={`${error ? "text-red-500" : "text-blue-500"}`}>
              *
            </span>
          )}
        </label>
      )}
      <small
        className={classNames(
          "text-gray-800 px-4 py-3 bg-green-300 font-bold tracking-wider text-sm",
          {
            block: helperText,
            hidden: !helperText,
          }
        )}
      >
        {helperText}
      </small>
    </div>
  )
}

export const RadioField = ({
  fullWidth,
  className,
  name,
  label,
  options,
  value,
  required,
  onChange,
  error,
  helperText,
}) => {
  return (
    <>
      {label && (
        <>
          <p className="mb-2 mt-4 text-base text-center text-gray-700">
            {label}
          </p>
          {required && (
            <span className={`${error ? "text-red-500" : "text-blue-500"}`}>
              *
            </span>
          )}
        </>
      )}
      <ul className={classNames("relative", className)}>
        {options.map((option) => (
          <li
            key={option.value}
            className={classNames("relative", {
              "w-full": fullWidth,
              "": error,
            })}
          >
            <input
              className="sr-only peer"
              id={option.id}
              type="radio"
              name={name}
              onChange={onChange}
              aria-label={option.label}
              disabled={option.disabled}
              key={option.value}
              value={option.value}
            />
            <label
              className={classNames(
                "flex px-5 py-2 bg-gray-200 dark:bg-gray-900 border-b-2 border-gray-300 rounded-xl cursor-pointer focus:outline-none hover:bg-green-50 peer-checked:ring-blue-400 peer-checked:ring-4 peer-checked:border-transparent peer-checked:bg-green-50 dark:peer-checked:bg-gray-900",
                {
                  "": error,
                }
              )}
              htmlFor={option.id}
            >
              {option.label ? option.label : ""}
            </label>
          </li>
        ))}
      </ul>
      <small
        className={classNames(
          "text-gray-800 px-4 py-3 bg-green-300 font-bold tracking-wider text-sm",
          {
            block: helperText,
            hidden: !helperText,
          }
        )}
      >
        {helperText}
      </small>
    </>
  )
}

export const FilesField = ({
  fullWidth,
  className,
  id,
  placeholder,
  name,
  label,
  value,
  multiple,
  required,
  onChange,
  error,
  helperText,
}) => {
  return (
    <div className={classNames("relative", className)}>
      <input
        placeholder={placeholder}
        className={classNames(
          "peer px-3 font-semibold shadow font-body tracking-wide bg-white dark:bg-gray-950 h-14 text-lg text-gray-900 dark:text-gray-200 border-b-2 border-gray-300 focus:border-gray-700 dark:focus:border-gray-200 placeholder-transparent focus:outline-none rounded-xl",
          {
            "w-full": fullWidth,
            "": error,
          }
        )}
        id={id}
        type="file"
        multiple={multiple}
        name={name}
        value={value}
        onChange={onChange}
      />
      {label && (
        <label
          className={classNames(
            "absolute font-bold font-body left-0 -top-6 text-gray-600 dark:text-gray-400 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-3 peer-focus:-top-6 peer-focus:text-gray-600 dark:peer-focus:text-gray-200 peer-focus:text-sm",
            {
              "": error,
            }
          )}
          htmlFor={id}
        >
          {label + " "}
          {required && (
            <span className={`${error ? "text-red-500" : "text-blue-500"}`}>
              *
            </span>
          )}
        </label>
      )}
      <small
        className={classNames(
          "text-gray-800 px-4 py-3 bg-green-300 font-bold tracking-wider text-sm",
          {
            block: helperText,
            hidden: !helperText,
          }
        )}
      >
        {helperText}
      </small>
    </div>
  )
}

export const ButtonField = ({
  fullWidth,
  className,
  small,
  type,
  color,
  dark,
  variant,
  children,
  title,
  onClick,
  isLoading,
}) => {
  return (
    <button
      title={title}
      disabled={isLoading}
      onClick={
        onClick && !isLoading
          ? onClick
          : (e) => (!isLoading ? e : e.preventDefault())
      }
      className={classNames(
        "shadow-md font-body font-bold uppercase tracking-wider disabled:opacity-20",
        className,
        {
          "w-full": fullWidth,
          "px-4 py-2": small,
          "px-6 py-4": !small,
          // "dark:filter dark:invert": dark,
          "text-gray-700 bg-gray-50":
            color === "info" && variant === "contained",
          "text-white bg-gray-700 dark:text-gray-700 dark:bg-gray-200":
            color === "secondary" && variant === "contained",
          "text-gray-700 bg-white dark:text-gray-50 dark:bg-gray-950":
            color === "primary" && variant === "contained",
          "text-gray-700 border-2 border-gray-700 dark:text-gray-50 dark:border-gray-50":
            (color === "primary" || color === "secondary") &&
            variant === "outlined",
        }
      )}
      type={type}
    >
      {!isLoading ? children : <VscLoading className="animate-spin" />}
    </button>
  )
}
