/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import BaseContext from "../context/baseContext"
import { titleIfy, slugify } from "../utils/helpers"
// import { colors } from "../theme"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, Slide } from "react-toastify"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "../components/Menu"
import { ContentProvider } from "../services/context/content-context"

const config = {
  // privacyPolicy: "/#mentions-legales",
  cookieName: "consent",
  translations: {
    fr: {
      acceptSelected: "Accepter",
      consentModal: {
        description:
          "Ici, vous pouvez voir et personnaliser les informations que nous recueillons à votre sujet.",
      },
      googleAnalytics: {
        description:
          "Outil d'audience de site web Google Analytics nous permettant de voir comment les visiteurs utilisent notre site Web.",
      },
      purposes: {
        analytics: "Analytics",
      },
    },
  },
  services: [
    {
      name: "googleAnalytics",
      default: true,
      title: "Google Analytics",
      purposes: ["analytics"],
      cookies: ["_ga", "_gcl_au", "_gid", "_gat"],
      required: false,
      optOut: true,
      onlyOnce: false,
    },
  ],
}

const Layout = (props) => {
  const headerRef = React.useRef(null)

  const { directus } = useStaticQuery(graphql`
    query {
      directus: allDirectus {
        pages_settings {
          wines_title
        }
        wines_aggregated {
          count {
            price_be
            price_fr
          }
        }
      }
    }
  `)

  useEffect(() => {
    document.documentElement.classList.add(
      "scrollbar-thumb-gray-400",
      "scrollbar-thin",
      "scrollbar-track-transparent",
      "scrollbar-thumb-rounded"
    )
  }, [])

  useEffect(() => {
    // if (typeof window !== "undefined" && window) {
    import("klaro")
      .then((Klaro) => {
        window.klaro = Klaro
        window.klaroConfig = config
        Klaro.setup(config)
      })
      .catch((error) => console.error(error))
    // }
  })

  const { children } = props
  let links = []

  links.push({
    name: titleIfy("Histoire"),
    link: "/" + slugify("Histoire"),
  })
  links.push({
    name: titleIfy(directus.pages_settings.wines_title),
    link: "/" + slugify("Les Vins"),
  })

  /* MENU TARIFS */
  // directus.wines_aggregated[0] &&
  //   directus.wines_aggregated[0].count.price_be > 0 &&
  //   directus.wines_aggregated[0].count.price_fr > 0 &&
  //   links.push({
  //     name: titleIfy("Tarifs"),
  //     link: "/" + slugify("Tarifs"),
  //   })

  links.push({
    name: titleIfy("Contact"),
    link: "/" + slugify("Contact"),
  })

  return (
    <BaseContext.Provider value={headerRef}>
      <ContentProvider>
        <div className="relative bg-white selection:bg-stone-800 selection:text-stone-100">
          <Menu fixed="true" links={links} />
          <div className="min-h-screen p-0 m-0 flex justify-center">
            <main className="max-w-c_4k w-full mx-auto">{children}</main>
          </div>
        </div>
        <ToastContainer
          icon={false}
          hideProgressBar={false}
          pauseOnHover={true}
          autoClose={3000}
          closeButton={false}
          closeOnClick={true}
          transition={Slide}
        />
      </ContentProvider>
    </BaseContext.Provider>
  )
}

export default Layout
