exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-histoire-js": () => import("./../../../src/pages/histoire.js" /* webpackChunkName: "component---src-pages-histoire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-les-vins-index-js": () => import("./../../../src/pages/les-vins/index.js" /* webpackChunkName: "component---src-pages-les-vins-index-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-templates-wine-template-js": () => import("./../../../src/templates/WineTemplate.js" /* webpackChunkName: "component---src-templates-wine-template-js" */)
}

