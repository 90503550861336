import { useCallback, useContext } from "react"
import { ContentContext } from "./content-context"

export function useModal() {
  // The useContext values are the state and dispatch given to the
  // `value` prop for the `ContentContext.Provider` in `contentContext.tsx`
  const [{ modal }, dispatch] = useContext(ContentContext)
  const setModal = useCallback(
    (modal) => dispatch("setModal", modal),
    [dispatch]
  )
  // const doubleFoo = useCallback(() => dispatch("doubleFoo"), []);
  return {
    modal,
    setModal,
    // doubleFoo,
    // You could also pass `dispatch` through directly. Since we
    // have it typed properly typescript will restrict its uses
    // to the defined Actions union in our reducer file.
  }
}
