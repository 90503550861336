// All code is free, use it.
// https://gist.github.com/magalhaespaulo/737a5c35048c18b8a2209d8a9fae977c
//
import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { AnimatePresence, motion } from "framer-motion"
import FocusLock from "react-focus-lock"
import { ButtonField } from "../Fields"
import { IoCloseCircle } from "react-icons/io5"

const effect = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 600,
      damping: 30,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
}

const Backdrop = ({ children, handleClose }) => (
  <motion.div
    className="
      z-50 fixed inset-0
      flex items-center justify-center
      bg-gray-800 bg-opacity-80 bg-backdrop backdrop-filter backdrop-blur-sm
    "
    onClick={handleClose}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    {children}
  </motion.div>
)

const ModalContent = ({
  className,
  children,
  handleClose,
  ariaLabel,
  isLoading,
}) => (
  <div className="w-full h-full flex items-start flex-col">
    {handleClose && (
      <ButtonField
        isLoading={isLoading}
        type="button"
        variant="contained"
        color="info"
        className="relative shrink-0 w-screen mx-0 z-50 flex justify-center items-center"
        onClick={handleClose}
        aria-label={`Close ${ariaLabel || "dialog"}`}
      >
        {/* <svg
          className="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
        </svg> */}
        <IoCloseCircle className="text-inherit mr-2" size={25} />
        Fermer
      </ButtonField>
    )}
    <div className="h-full w-full flex justify-center items-center mt-2 mb-2">
      <motion.div
        tabIndex={-1}
        role="dialog"
        aria-modal={true}
        aria-label={ariaLabel}
        className={`relative max-w-[480px] w-full mx-auto max-h-[80vh] overflow-auto ${
          className || "p-5 bg-gray-50 rounded-xl shadow-lg"
        }`}
        variants={effect}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={(event) => event.stopPropagation()}
      >
        {children}
      </motion.div>
    </div>
  </div>
)

const Modal = ({
  children,
  className,
  isOpen,
  handleClose,
  hideCloseButton,
  backdropDismiss = false,
  onExitComplete,
  ariaLabel,
  isLoading,
}) => {
  const [isBrowser, setIsBrowser] = useState(false)
  const [trigger, setTrigger] = onExitComplete ?? [undefined, undefined]

  const handleKeyDown = useCallback(
    (event) => {
      if (!isOpen || event.key !== "Escape") return
      typeof handleClose === "function" && handleClose()
    },
    [isOpen, handleClose]
  )

  useEffect(() => {
    if (!isOpen) return

    document.documentElement.classList.remove("scrollbar-thin", "overflow-auto")
    document.documentElement.classList.add("overflow-hidden")
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.documentElement.classList.remove("overflow-hidden")
      document.documentElement.classList.add("scrollbar-thin", "overflow-auto")
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [isOpen, handleKeyDown])

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  if (!isBrowser) return <></>

  return (
    <AnimatePresence
      initial={false}
      exitBeforeEnter={true}
      onExitComplete={() =>
        setTrigger && trigger === "fired" && setTrigger("completed")
      }
    >
      {isOpen && (
        <Backdrop handleClose={backdropDismiss ? handleClose : undefined}>
          <FocusLock className="w-full h-full">
            <ModalContent
              className={className}
              handleClose={hideCloseButton ? undefined : handleClose}
              ariaLabel={ariaLabel}
              isLoading={isLoading}
            >
              {children}
            </ModalContent>
          </FocusLock>
        </Backdrop>
      )}
    </AnimatePresence>
    // document.getElementById('modal-portal')!
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  hideCloseButton: PropTypes.bool,
  backdropDismiss: PropTypes.bool,
  onExitComplete: PropTypes.array,
  ariaLabel: PropTypes.string,
}

export default Modal
