export const contentReducer = (state, action) => {
  switch (action.type) {
    case "setModal":
      return {
        ...state,
        modal: action.payload,
      }

    case "setFixedButton":
      return {
        ...state,
        fixedButton: action.payload,
      }
    default:
      throw new Error()
  }
}
