import React from "react"
import { Link } from "gatsby"
import { ContextProviderComponent, SiteContext } from "../context/mainContext"
import { FaWineBottle } from "react-icons/fa"
import { IoBagHandle } from "react-icons/io5"
// import { colors } from "../theme"

function CartLink(props) {
  let {
    context: { numberOfItemsInCart = 0 },
  } = props
  return (
    <>
      <Link
        title="Aller au panier"
        to="/cart"
        className="relative h-full flex justify-center items-center px-2 sm:px-3 pr-1 pb-[5px]"
      >
        <IoBagHandle
          className={`z-10 ${
            props.compactBar ? "text-gray-700" : "text-gray-100"
          }`}
          size={30}
        />
        <div
          className={`${
            numberOfItemsInCart > Number(0) ? "absolute" : "hidden"
          } left-6 top-2 z-0`}
        >
          <FaWineBottle
            className={`${
              props.compactBar ? "text-gray-700" : "text-gray-100"
            }`}
            size={15}
          />
        </div>
      </Link>
    </>
  )
}

function CartLinkWithContext(props) {
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {(context) => <CartLink {...props} context={context} />}
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}

export default CartLinkWithContext
