import { useCallback, useContext } from "react"
import { ContentContext } from "./content-context"

export function useFixedButton() {
  // The useContext values are the state and dispatch given to the
  // `value` prop for the `ContentContext.Provider` in `contentContext.tsx`
  const [{ fixedButton }, dispatch] = useContext(ContentContext)

  const setFixedButton = useCallback(
    (fixedButton) => dispatch("setFixedButton", fixedButton),
    [dispatch]
  )
  // const doubleFoo = useCallback(() => dispatch("doubleFoo"), []);
  return {
    fixedButton,
    setFixedButton,
    // doubleFoo,
    // You could also pass `dispatch` through directly. Since we
    // have it typed properly typescript will restrict its uses
    // to the defined Actions union in our reducer file.
  }
}
